import React, { useState, useCallback, useEffect, useContext, useRef } from 'react'
import styles from './style.module.scss'
import { Mask, Toast } from 'antd-mobile'
import { Title } from '../components/common'
import close from '@/assets/img/landfi/icon_del.png'
import { LandDetailContext } from "../landDetail/context"
import { usePurchaseCard } from './purchaseCard'
import ListView from "@/components/AntListView"
import { myAccelerationCardList, exerciseAccelerationCard } from '@/api/guland'
import { hideLoading, showLoading } from "@/components/loading"

const MyAsseleratorCard = ({visible, onClose}) => {
  const [cardList, setCardList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [cardInfo, setCardInfo] = useState({})
  const currentPage = useRef(1);
  const pageSize = useRef(20);
  const domRef = useRef(null)

  const { landInfo, expGemInfo } = useContext(LandDetailContext);

  const [onOpen, CardConfirm] = useCardConfirm({cardInfo, closeMyAsseleratorCard: () => onClose()})
  const [onPurchaseCardOpen, PurchaseCardView] = usePurchaseCard({from: 'myCard', refreshMyCard: () => getSpeedCardList(true, false)})

  useEffect(() => {
    if (visible) {
      getSpeedCardList(true, true)
    }
  }, [visible])

  const getSpeedCardList = async (isRefresh=true, loading=false) => {
    try {
      loading && showLoading()
      if(isRefresh) {
        currentPage.current = 1;
        domRef.current?.scroll(0, 0)
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
      }
      const res = await myAccelerationCardList(params)
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setCardList(res?.data?.userAccelerationCardsDOList || []);
      } else {
        setCardList([...cardList, ...res?.data?.userAccelerationCardsDOList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.userAccelerationCardsDOList.length >= pageSize.current);
    } catch (error) {
      console.log(error)
      loading && hideLoading()
      Toast.show(error)
    }
  }

  const onImmediateUse = (item) => {
    if (landInfo.setFlag === 0) {
      Toast.show('请先放置此土地')
      return
    }
    if (expGemInfo.isUseCard === 1) {
      Toast.show('此土地已使用了加速卡')
      return
    }
    onOpen()
    setCardInfo(item)
    console.log(item)
  }

  const onPurchaseMore = () => {
    onPurchaseCardOpen()
  }

  const SpeedCardItem = (item) => {
    const { num, times, cardName, image } = item || {}
    return <div className={styles.speed_card_item}>
      <div className={styles.card_count}>{num}</div>
      <div className={styles.multiple}><span>{times}倍!</span></div>
      <div className={styles.middle_img} style={{backgroundImage: image ? `url(${image})` : undefined}}></div>
      <div className={styles.day_exp}>{cardName}</div>
      <div className={`${styles.use_btn} ${styles.use_btn_family}`} onClick={() => onImmediateUse(item)}>立即使用</div>
    </div>
  }

  return <Mask 
    visible={visible} 
    destroyOnClose
    className={styles.speed_card_mask}
    style={{background: 'rgba(0, 0, 0)'}}
  >
    <div className={styles.my_asselerator_card}>
      <Title title="我的加速卡" className={styles.title}/>
      <img src={close} alt="" onClick={onClose} className={styles.close_btn}/>
      {cardList?.length > 0 && <div className={styles.purchase_more} onClick={onPurchaseMore}>购买更多加速卡&gt;&gt;</div>}
      <div className={styles.card_list} ref={domRef}>
        {
          cardList?.length > 0 ? <ListView
            dataSource={cardList}
            hasMore={hasMore}
            style={{paddingTop: 5}}
            renderItem={SpeedCardItem}
            onEndReached={async () => {
              await getSpeedCardList(false, false);
            }}
            isLoad={isLoad}
          />: !isLoad ? null: <div className={styles.no_card}>
            <div className={styles.empty_box}></div>
            <p>您的卡包空空如也<br />快去购买加速卡吧</p>
            <div className={styles.purchase_btn} onClick={onPurchaseMore}>购买加速卡</div>
          </div>
        }
      </div>
      {CardConfirm}
      {PurchaseCardView}
    </div>
  </Mask>
}

const SpeedCardConfirm = ({visible, onClose, cardInfo, closeMyAsseleratorCard}) => {
  const { landInfo, refreshExp, refreshDailyExp, currentTab } = useContext(LandDetailContext);

  const confirmUse = async () => {
    try {
      showLoading()
      const res = await exerciseAccelerationCard({cardId: cardInfo.cardId, landId: landInfo.id})
      hideLoading()
      const { code, msg } = res
      if (code !== '000000') throw msg
      onClose()
      closeMyAsseleratorCard()
      refreshExp()
      currentTab === '2' && refreshDailyExp()
    } catch (error) {
      hideLoading()
      onClose()
      Toast.show(error)
    }
  }

  return <Mask 
    visible={visible} 
    destroyOnClose
    style={{
      background: 'rgba(0, 0, 0, .7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className={styles.speed_card_confirm}>
      <div className={styles.header}>
        <span></span>
        <span>使用加速卡</span>
        <img src={close} alt="" onClick={() => onClose()}/>
      </div>
      <p>确认对#{landInfo?.nftNo}号地使用加速卡</p>
      <div className={styles.used_land_img}>
        <img src={landInfo?.nftImage} alt="" />
      </div>
      <div className={styles.btn_box}>
        <div className={`${styles.cancel_btn} ${styles.common_btn}`} onClick={() => onClose()}>取消</div>
        <div className={`${styles.confirm_btn} ${styles.common_btn}`} onClick={() => confirmUse()}>确认</div>
      </div>
    </div>
  </Mask>
}

const useCardConfirm = ({cardInfo, closeMyAsseleratorCard}) => {
  const [visible, setVisible] = useState(false)

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const confirmView = (
    <SpeedCardConfirm 
      visible={visible} 
      onClose={onClose} 
      cardInfo={cardInfo} 
      closeMyAsseleratorCard={closeMyAsseleratorCard}
    />
  )

  return [onOpen, confirmView]
}

export const useSpeedCard = () => {
  const [visible, setVisible] = useState(false)

  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])

  const cardView = (
    <MyAsseleratorCard visible={visible} onClose={onClose}/>
  )

  return [onOpen, cardView]
}
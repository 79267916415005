import classNames from "classnames";
import React, { useContext } from "react";
import selectImg from '@/assets/img/landfi/add-ape.png';
import CountDown from "./countDown";
import { residueHours, residue12Hours } from '../utils';
import { MonkeyMap } from './landview';
import { LandDetailContext } from "../landDetail/context";

const domnTime = (current, set, isFirstTime) => {
  if (isFirstTime === 1) {
    if (residue12Hours(set) >= 0) {
      const currentTime = Date.parse(current?.replace(/-/g, '/'))
      const setTime = Date.parse(set?.replace(/-/g, '/'))
      const residueTime = currentTime - setTime
      return residueTime < 12*60*60*1000
    } else {
      return false
    }
  } else {
    return residueHours(current) >= 12
  }
}

const landaStyleMap = {48: 'landa', 63: 'landa2'}

export const LandA = ({ 
  disabled, 
  scale = 1, 
  style = {}, 
  hideApe=true, 
  workApeList, 
  onSelect,
  workStyle = true,
  currentTime = '',
  img,
  ...props 
}) => {
  const { landInfo } = useContext(LandDetailContext)
  
  return <div
    className={classNames(`land-game-${landaStyleMap[landInfo.collectionId] || 'landa'}`, { disabled }, {landaWork: workStyle})}
    style={{ ...style, transform: `scale(${scale})`, backgroundImage: img ? `url(${img})` : undefined }}
    {...props}
  >
    {!hideApe ? workApeList?.length > 0 && workApeList.map((item, index) => {
      return <div 
      className={classNames(`land-game-${landaStyleMap[landInfo.collectionId] || 'landa'}-workApe`, `ape_${index}`, {active: item.isChoose})}
        key={index} 
        onClick={() => onSelect(item)}
      >
        {MonkeyMap[item.monkeyType] && domnTime(currentTime, item.setTime, item.isFirstTime) ? <div className={`land-game-${landaStyleMap[landInfo.collectionId] || 'landa'}-workApe-time`}>
          <CountDown sysTime={currentTime} placeTime={item.setTime} isTomorrow={item.isFirstTime === 0}/>
        </div>: null}
        <img className={classNames({apeImg: MonkeyMap[item.monkeyType]})} src={MonkeyMap[item.monkeyType] ? MonkeyMap[item.monkeyType]: selectImg} alt="" />
      </div>
    }): null}
  </div>
};
export const LandB = ({ 
  disabled, 
  scale = 1, 
  style = {}, 
  hideApe=true, 
  workApeList,
  onSelect, 
  workStyle = true,
  currentTime = '',
  img,
  ...props 
}) => {
  return <div
    className={classNames("land-game-landb", { disabled }, {landbWork: workStyle})}
    style={{ ...style, transform: `scale(${scale})`, backgroundImage: img ? `url(${img})` : undefined }}
    {...props}
  >
    {!hideApe ? workApeList?.length > 0 && workApeList.map((item, index) => {
      return <div 
        className={classNames("land-game-landb-workApe", `ape_${index}`, {active: item.isChoose})} 
        key={index} 
        onClick={() => onSelect(item)}
      >
        {MonkeyMap[item.monkeyType] && domnTime(currentTime, item.setTime, item.isFirstTime) ? <div className="land-game-landb-workApe-time">
          <CountDown sysTime={currentTime} placeTime={item.setTime} isTomorrow={item.isFirstTime === 0}/>
        </div>: null}
        <img className={classNames({apeImg: MonkeyMap[item.monkeyType]})} src={MonkeyMap[item.monkeyType] ? MonkeyMap[item.monkeyType]: selectImg} alt="" />
      </div>
    }): null}
  </div>
};

export const LandC = ({ 
  disabled, 
  scale = 1, 
  img, 
  style = {}, 
  hideApe=true, 
  workApeList, 
  workStyle = true,
  currentTime = '',
  onSelect,
  ...props 
}) => {

  return <div
    className={classNames("land-game-landc", { disabled }, {landcWork: workStyle})}
    style={{
      ...style,
      transform: `scale(${scale})`,
      backgroundImage: img ? `url(${img})` : undefined,
    }}
    {...props}
  >
    {!hideApe ? workApeList?.length > 0 && workApeList.map((item, index) => {
      return <div 
        className={classNames("land-game-landc-workApe", `ape_${index}`, {active: item.isChoose})} 
        key={index} 
        onClick={() => onSelect(item)}
      >
        {MonkeyMap[item.monkeyType] && domnTime(currentTime, item.setTime, item.isFirstTime) ? <div className="land-game-landc-workApe-time">
          <CountDown sysTime={currentTime} placeTime={item.setTime} isTomorrow={item.isFirstTime === 0}/>
        </div>: null}
        <img className={classNames({apeImg: MonkeyMap[item.monkeyType]})} src={MonkeyMap[item.monkeyType] ? MonkeyMap[item.monkeyType]: selectImg} alt="" />
      </div>
    }): null}
  </div>
};

export const LandMap = {
  a: <LandA></LandA>,
  b: <LandB></LandB>,
  c: <LandC></LandC>,
};

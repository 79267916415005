import React, { useEffect, useMemo, useState,useRef } from "react";
import styles from "./styles.module.scss";
import { ScoreXBtn } from "../components/button";
import close from '@/assets/img/landfi/icon_del.png';
import LandLease from "./landLease";
import ApeLease from "./apeLease";
import MyLeaseOut from "./myLeaseOut";
import MyLease from "./myLease";
import RendOut from "./rentOut"
import GemDetail from "./rentOut/gemDetail"
import { getGemstoreCount } from '@/api/guland'

const LeaseCenter = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [rendOutVisible, setRendOutVisible] = useState(false)
  const [gemDetailVisible, setGemDetailVisible] = useState(false)
  const [gemstoreCounts, setGemstoreCounts] = useState(0)

  const pageRef = useRef(null)

  useEffect(() => {
    queryGemstoreCounts()
    window.JsBridgeNew.addKeyboardEvent()
  }, [])

  const TabConentMap = useMemo(() => {
    return {
      0: <LandLease />,
      1: <ApeLease />,
      2: <MyLeaseOut />,
      3: <MyLease />,
    }
  }, [])

  const queryGemstoreCounts = async () => {
    try {
      const res = await getGemstoreCount()
      if (res?.code !== '000000') throw res?.msg
      setGemstoreCounts(res?.data?.gemstonesCount || 0)
    } catch (error) {
      console.log(error)
    }
  }

  return <div className={styles.land_lease_center} ref={pageRef}>
    <ScoreXBtn style={{top: '2vw', right: '13vw'}} gemCount={gemstoreCounts} onClick={() => setGemDetailVisible(true)}/>
    <img 
      className={styles.close} 
      src={close} 
      alt="" 
      onClick={() => {
        if (window.JsBridge.hasWebViewBridge()) {
          window.JsBridge.back();
        } else {
          window.history.go(-1);
        }
      }}
    />
    <div className={styles.left_tabs}>
      <div className={styles.tabs_title}>租赁中心</div>
      <div className={styles.tabs_top}>
        <div className={`${styles.tabs_item} ${currentTab === 0 ? styles.active: ''}`} onClick={() => setCurrentTab(0)}>土地</div>
        <div className={`${styles.tabs_item} ${currentTab === 1 ? styles.active: ''}`} onClick={() => setCurrentTab(1)}>无聊猿</div>
      </div>
      <div className={styles.tabs_bottom}>
        <div>
          <div className={`${styles.tabs_item} ${currentTab === 2 ? styles.active: ''}`} onClick={() => setCurrentTab(2)}>我出租的</div>
          <div className={`${styles.tabs_item} ${currentTab === 3 ? styles.active: ''}`} onClick={() => setCurrentTab(3)}>我租来的</div>
        </div>
        <div className={styles.tabs_item_last}  onClick={() => setRendOutVisible(true)}></div>
      </div>
    </div>
    <div className={styles.tabs_content}>{TabConentMap[currentTab]}</div>
   {rendOutVisible && <RendOut 
      visible={rendOutVisible} 
      onclose={(refresh) => {
        setRendOutVisible(false)
      }}
      container={pageRef.current}
      />
    }
    {gemDetailVisible && <GemDetail 
      visible={gemDetailVisible} 
      onclose={(refresh) => {
        setGemDetailVisible(false)
      }}
      container={pageRef.current}
      />}
  </div>
}

export default LeaseCenter;
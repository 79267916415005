import React, { useState, useRef, useEffect } from "react";
import Scenes from "./components/scenes";
import {
  MyLandBtn,
  TeleportBtn,
  SpeedBtn,
  ScoreBtn,
  HomeBackBtn,
  SpaceId,
  TaskBtn,
  ExchangeLandBtn,
  LandLease,
  SpaceExtend,
  SpaceNum,
} from "./components/button";
import LandView from "./components/landview";
import LandDetail from "./landDetail";
import PlaceLand from "./components/placeLand";
import MyLands from "./components/myLands";
import ReceiveLand from "./components/receiveLand";
import RealName from "./components/realName";
import { ReceiveTip } from "./components/landview";
import { Toast } from "antd-mobile";
import { gulandInit, confirmReceiveLands, confirmPlaceLand, cancelPlacement } from "@/api/guland";
import {hideLoading, showLoading} from "@/components/loading";
import DailyTask from "./dailyTask";
import { usePurchaseCard } from "./acceleratorCard/purchaseCard"
import { usePortal } from './components/portal'
import {useNavigate} from "react-router-dom";
import ExchangeLand from "./components/exchangeLand";
import MyModal from "./jewelLand/modal";
import ConfirmLandOrder from "./components/landOrder";
import SpaceExtendModal from "./components/spaceExtend";
import qs from 'query-string';

import "./index.scss";

let level = 1;

const LandGame = () => {
  const navigate = useNavigate()
  const [placeVisible, setPlaceVisible] = useState(false)
  const [exchangeVisible, setExchangeVisible] = useState(false)
  const [myLandsVisible, setMyLandsVisible] = useState(false)
  const [taskVisible, setTaskVisible] = useState(false)
  const [spaceExtendVisible, setSpaceExtendVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [initData, setInitData] = useState({})
  const [upgradeData, setUpgradeData] = useState(null)
  const [isCanExchangeLand, setIsCanExchangeLand] = useState(false)
  const chooseLandId = useRef(null)
  const teleportRef = useRef();
  const timeId = useRef(null)
  const pageRef = useRef(null)
  const currentClickLand = useRef(null)
  const spaceId = useRef(null)
  const spaceLevel = useRef(1)

  const { isUnityHome } = qs.parse(window.location.search);

  const [onOpen, PurchaseCard] = usePurchaseCard()
  const [onPortalOpen, PortalView] = usePortal({initData, confirmCB: (id) => {
    spaceId.current = id
    spaceLevel.current = 1
    pageInit(id)
  }})

  useEffect(() => {
    window.JsBridgeNew.hiddenStatusBar(true)
    window.JsBridgeNew.screenRotate(false)
  }, [])

  useEffect(() => {
    getUserInfo()
    onDidFocus()
    pageInit()
    return () => {
      clearInterval(timeId.current)
    }
  }, [])

  const onLandClick = (type, selectLand) => {
    if (initData?.isSelf === 0) return
    currentClickLand.current = selectLand
    chooseLandId.current = selectLand?.separateNo
    if (type === 'place') {
      console.log('place')
      setPlaceVisible(true)
    } else if (type === 'check') {
      console.log('check')
      if (isCanExchangeLand) {
        setExchangeVisible(true)
        return
      }
      teleportRef.current?.show({...selectLand, from: 1})
    } else if (type === 'receive') {
      console.log('receive')
      // ReceiveLand.open({
      //   onConfirmReceive: () => {
      //     if (!userInfo.ID) {
      //       RealName.open({
      //         confirmCB: () => {
      //           window.JsBridge.navigate('Verify')
      //           window.JsBridgeNew.hiddenStatusBar(false)
      //           window.JsBridgeNew.screenRotate(true)
      //         }
      //       })
      //       return
      //     }
      //     receiveGiveLands()
      //   }
      // })
      if (!userInfo.ID) {
        RealName.open({
          confirmCB: () => {
            window.JsBridge.navigate('Verify')
            window.JsBridgeNew.hiddenStatusBar(false)
            window.JsBridgeNew.screenRotate(true)
          }
        })
        return
      }
      const ModalInstance = MyModal.show({
        content: <ConfirmLandOrder onClose={() => ModalInstance?.close()}/>,
        getContainer: pageRef.current,
      })
    }
  };

  //获取用户信息
  const getUserInfo = () => {
    window.JsBridge.getDetailCurrentUserInfo().then((res) => {
      setUserInfo(res);
    })
  }

  // 监听页面返回
  const onDidFocus = () => {
    window.JsBridge.addListener().then(() => {
      window.JsBridgeNew.hiddenStatusBar(true)
      window.JsBridgeNew.screenRotate(false)
      spaceLevel.current = level
      getUserInfo();
      onDidFocus();
      pageInit();
    });
  }

  // 页面初始化数据
  const pageInit = async (spaceNo, loading=true) => {
    try {
      loading && showLoading()
      const res = await gulandInit({spaceNo: spaceNo || spaceId.current, spaceLevel: spaceLevel.current})
      loading && hideLoading()
      if (res.code !== '000000') throw res.msg
      setInitData(res.data || {})
      setIsCanExchangeLand(false)
    } catch (error) {
      console.log(error)
      loading && hideLoading()
      Toast.show(error)
    }
  }

  // 领取土地
  const receiveGiveLands = async () => {
    try {
      showLoading()
      const res = await confirmReceiveLands()
      if (res.code !== '000000') throw res.msg
      hideLoading()
      await pageInit()
      Toast.show('领取成功')
    } catch (error) {
      console.log(error)
      Toast.show(error)
      hideLoading()
    }
  }

   // 放置土地
   const placeLands = async (params) => {
    try {
      showLoading()
      const res = await confirmPlaceLand({...params, spaceLevel: spaceLevel.current})
      if (res.code !== '000000') throw res.msg
      hideLoading()
      await pageInit()
    } catch (error) {
      console.log(error)
      hideLoading()
      Toast.show(error)
    }
  }

  // 取消放置土地
  const cancelPlaceLands = async () => {
    try {
      showLoading()
      const res = await cancelPlacement({landNftNo: currentClickLand.current?.nftNo})
      hideLoading()
      if (res.code !== '000000') throw res.msg
      setExchangeVisible(false)
      await pageInit()
    } catch (error) {
      console.log(error)
      hideLoading()
      Toast.show(error)
    }
  }

  const upgradeCallBack = async (upData) => {
    if (timeId.current) {
      clearInterval(timeId.current)
    }
    try {
      const res = await gulandInit({spaceNo: null, spaceLevel: spaceLevel.current})
      if (res.code !== '000000') throw res.msg
      setUpgradeData(upData)
      timeId.current = setTimeout(() => {
        setUpgradeData(null)
        setInitData(res.data || {})
      }, 2000);
    } catch (error) {
      console.log(error)
      Toast.show(error)
    }
  }

  const onLandLease = () => {
    if (window.JsBridge.hasWebViewBridge()) {
      window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/landGame/leaseCenter`});
    } else {
      navigate(`/landGame/leaseCenter`);
    }
  }

  const onLeftArrow = () => {
    if (spaceLevel.current <= 1) return
    spaceLevel.current = spaceLevel.current - 1
    level = spaceLevel.current
    pageInit()
  }

  const onRightArrow = () => {
    if (spaceLevel.current >= initData.hasSpaceLevel) return
    spaceLevel.current = spaceLevel.current + 1
    level = spaceLevel.current
    pageInit()
  }

  const onSpaceExtent = () => {
    setSpaceExtendVisible(true)
  }

  return (
    <div
      className="land-game"
      style={{ width: "100%", height: "100%", position: "relative" }}
      ref={pageRef}
    >
      <Scenes></Scenes>
      <SpaceNum spaceNum={spaceLevel.current}/>
      <HomeBackBtn 
        onClick={() => {
          if (initData?.isSelf === 0) {
            spaceId.current = null
            spaceLevel.current = 1
            pageInit()
            return
          }
          if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.back();
            if (!isUnityHome) {
              window.JsBridgeNew.hiddenStatusBar(false)
              window.JsBridgeNew.screenRotate(true)
            }
          } else {
            window.history.go(-1);
          }
        }}
      />
      <div className={`land-game-leftarrow ${spaceLevel.current <= 1 ? '': 'activity'}`} onClick={onLeftArrow}></div>
      <div className={`land-game-rightarrow ${spaceLevel.current >= initData.hasSpaceLevel ? 'disabled': ''}`} onClick={onRightArrow}></div>
      <SpaceId spaceId={initData?.spaceNo} isCustom={initData?.customNiceNo} spaceNiceNo={initData?.spaceNiceNo}/>
      {initData?.isSelf === 0 ? null: <div>
        <TaskBtn onClick={() => setTaskVisible(true)}/>
        <ScoreBtn 
          onClick={() => {
            if (window.JsBridge.hasWebViewBridge()) {
              window.JsBridge.push("CustomWeb", { uri: `${window.location.origin}/landGame/jewelLand`});
            } else {
              navigate(`/landGame/jewelLand`);
            }
          }} 
          storeCounts={initData?.gemstonesCount}
        ></ScoreBtn>
        {initData?.landBlocksDTOList?.length <= 0 ? <ReceiveTip onClick={onLandClick} receiveNum={initData?.receiveNum}/>: null}
        <SpeedBtn onClick={() => onOpen()}></SpeedBtn>
        <MyLandBtn onClick={() => setMyLandsVisible(true)}></MyLandBtn>
        <TeleportBtn onClick={() => onPortalOpen()}></TeleportBtn>
        <LandLease onClick={onLandLease}/>
        <SpaceExtend onClick={onSpaceExtent}/>
        {initData?.landBlocksDTOList?.length > 0 ? <ExchangeLandBtn onClick={() => setIsCanExchangeLand((pre) => !pre)} isCanExchangeLand={isCanExchangeLand}/>: null}
      </div>}
      <LandView 
        onClick={onLandClick} 
        landData={{...initData}} 
        upgradeData={upgradeData}
        isCanExchangeLand={isCanExchangeLand}
      ></LandView>
      <PlaceLand 
        visible={placeVisible} 
        onClose={() => setPlaceVisible(false)}
        onConfirm={(item) => placeLands(item)}
        landId={chooseLandId.current}
      />
      <ExchangeLand
        visible={exchangeVisible} 
        onClose={() => setExchangeVisible(false)}
        onConfirm={(item) => placeLands(item)}
        landId={chooseLandId.current}
        workApeData={initData}
        cancelPlace={cancelPlaceLands}
      />
      <MyLands 
        visible={myLandsVisible} 
        onclose={() => {
          setMyLandsVisible(false)
        }}
        userInfo={{...userInfo, receiveNum: initData.receiveNum}}
        upgradeLand={(upLand) => {
          console.log(upLand)
          teleportRef.current?.show({...upLand, from: 2})
          setMyLandsVisible(false)
        }}
        container={pageRef.current}
      />
      <LandDetail ctrl={teleportRef} upgradeCallBack={upgradeCallBack} pageInit={pageInit}></LandDetail>
      <DailyTask visible={taskVisible} onClose={() => setTaskVisible(false)}/>
      <SpaceExtendModal 
        container={pageRef.current} 
        visible={spaceExtendVisible} 
        onClose={() => setSpaceExtendVisible(false)}
        refresh={() => pageInit()}
      />
      {PurchaseCard}
      {PortalView}
    </div>
  );
};

export default LandGame;

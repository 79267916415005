import React, { useState, useEffect, useRef } from 'react'
import styles from './placeLand.module.scss'
import { Popup, Tabs, Swiper, Mask, Toast } from 'antd-mobile'
import close from '@/assets/img/landfi/icon_del.png'
import noData from '@/assets/img/landfi/no-data.png'
import ListView from "@/components/AntListView"
import weixuanzhong from '@/assets/img/landfi/wei-xuan-zhong.png'
import xuanzhong from '@/assets/img/landfi/xuanzhong.png'
import { placeLandList } from '@/api/guland'

const TabsList = [
  {key: '0', title: 'C级'},
  {key: '1', title: 'B级'},
  {key: '2', title: 'A级'},
]

const TabMap = {
  '0': 3,
  '1': 2,
  '2': 1,
}

const PlaceLand = ({visible, onClose, onConfirm, landId})=>{
  const [currentTab, setCurrentTab] = useState('0')
  const [isChoice, setIsChoice] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [apeId, setApeId] = useState(null)
  const [currentChooseLand, setCurrentChooseLand] = useState(null)
  const swiperRef = useRef(null)

  useEffect(() => {
    if (!visible) {
      setCurrentTab('0')
      setIsChoice(false)
    }
  }, [visible])

  const choiceItem = (item) => {
    if (item.nftNo === currentChooseLand?.nftNo) {
      setCurrentChooseLand(null)
      setIsChoice(false)
    } else {
      setCurrentChooseLand({...item, separateNo: landId})
      setIsChoice(true)
    }
    setApeId(item.nftNo)
  }

  const onTabClick = (i) => {
    setCurrentTab(i)
    swiperRef.current.swipeTo(i)
  }

  const onSelectConfirm = () => {
    console.log(currentChooseLand)
    onConfirm(currentChooseLand)
    // setConfirmVisible(false)
  }

  return <>
    <Popup 
      visible={visible}
      position='left'
      destroyOnClose
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        height: '100%',
        background: 'transparent',
        paddingLeft: 0
      }}
    >
      <div className={styles.space_land}>
        <div className={styles.close_popup} onClick={onClose}><img src={close} alt="" /></div>
        <div className={styles.tabs_container}>
          <Tabs className={styles.tabs} activeKey={currentTab} onChange={(key) => onTabClick(key)}>
            {TabsList.map((item, index) => <Tabs.Tab 
              key={index} 
              title={<div className={`${styles.tab_title} ${currentTab === item.key ? styles.tab_select: ''}`}>{item.title}</div>}
            ></Tabs.Tab>)}
          </Tabs>
        </div>
        <div className={styles.tabs_content}>
          <Swiper
            direction='horizontal'
            indicator={() => null}
            ref={swiperRef}
            allowTouchMove={false}
            defaultIndex={currentTab}
          >
            <Swiper.Item style={{display: 'flex'}}>
              <LandList focusStatus={currentTab === '0'} type={TabMap[currentTab]} getChooseLand={choiceItem} isChoice={isChoice} apeId={apeId}/>
            </Swiper.Item>
            <Swiper.Item style={{display: 'flex'}}>
              <LandList focusStatus={currentTab === '1'} type={TabMap[currentTab]} getChooseLand={choiceItem} isChoice={isChoice} apeId={apeId}/>
            </Swiper.Item>
            <Swiper.Item style={{display: 'flex'}}>
              <LandList focusStatus={currentTab === '2'} type={TabMap[currentTab]} getChooseLand={choiceItem} isChoice={isChoice} apeId={apeId}/>
            </Swiper.Item>
          </Swiper>
        </div>
        <div 
          className={styles.confirm_btn_box} 
          onClick={() => {
            if (!isChoice) {
              return
            }
            onClose()
            onSelectConfirm()
            // setConfirmVisible(true)
          }}
        >
          <span className={`${styles.confirm_btn} ${isChoice ? styles.active: styles.disabled}`}>确认</span>
        </div>
      </div>
    </Popup>
    <Mask
      className={styles.confirm_mask}
      visible={confirmVisible}
    >
      <div className={styles.confirm_container}>
        <div className={styles.header}>
          <span className={styles.close_confirm_mask}></span>
          <span className={styles.title}>放置土地</span>
          <img className={styles.close_confirm_mask} src={close} alt="" onClick={() => setConfirmVisible(false)} />
        </div>
        <div className={styles.confirm_tip}>
          <p>确认放置#{currentChooseLand?.nftNo}土地吗？</p>
          <p>放置土地后不可更改</p>
        </div>
        <img className={styles.place_land_img} src={currentChooseLand?.nftImage} alt="" />
        <div className={styles.confirm_btn} onClick={onSelectConfirm}>确认放置</div>
      </div>
    </Mask>
  </>
  
}

const LandList = ({focusStatus, getChooseLand, isChoice, apeId, type}) => {
  const [initStatus, setInitStatus] = useState(false)
  const [landData, setLandData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    if (!initStatus && focusStatus) {
      setInitStatus(true);
      getLandList();
      return;
    }
  }, [initStatus, focusStatus])

  const getLandList = async (isRefresh=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        blockLevel: type,
      }
      console.log(params)
      const res = await placeLandList(params)
      console.log(res)
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setLandData(res?.data?.landBlocksDTOList || [])
      } else {
        setLandData([...landData, ...res?.data?.landBlocksDTOList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res.data.totalCount > [...landData, ...res?.data?.landBlocksDTOList].length);
    } catch (error) {
      console.log(error)
      Toast.show(error)
    }
  }

  const listItem = (item) => {
    return <div 
      className={styles.list_item}
      onClick={() => getChooseLand(item)}
    >
      <div className={styles.list_item_inner}>
        <img className={styles.land_img} src={item.nftImage} alt=""/>
        <p>#{item.nftNo}</p>
        <img className={styles.select_img} src={isChoice && item.nftNo === apeId ? xuanzhong: weixuanzhong} alt="" />
        {item.ownFlag === 1 ? <div className={styles.lease_tag}></div>: null}
      </div>
    </div>
  }
  return <div className={styles.land_list}>
    {
      landData?.length > 0 ? <ListView
        dataSource={landData}
        hasMore={hasMore}
        style={{paddingTop: 5}}
        renderItem={listItem}
        onEndReached={async () => {
          await getLandList(false);
        }}
        isLoad={isLoad}
      />: !isLoad ? null: <div className={styles.no_data}><img src={noData} alt="" /></div>
    }
  </div>
}

export default PlaceLand
import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import { Mask, Popup, Toast } from 'antd-mobile'
import { CloseBtn } from '../components/button'
import { Title } from '../components/common'
import exp from '@/assets/img/landfi/icon_exp.png'
import close from '@/assets/img/landfi/icon_del.png'
import weixuanzhong from '@/assets/img/landfi/wei-xuan-zhong.png'
import xuanzhong from '@/assets/img/landfi/xuanzhong.png'
import { dailyTaskStatus, dailyTaskToLand, spaceLandList } from '@/api/guland'
import { hideLoading, showLoading } from "@/components/loading"

export const StyleMap = {
  205: 'land_a',
  63: 'land_a',
  48: 'land_a',
  47: 'land_b',
  41: 'land_c',
  42: 'land_c',
  43: 'land_c',
  44: 'land_c',
  45: 'land_c5',
}

const BtnMap = {
  0: '去完成',
  1: '选择土地领取经验',
  2: '已完成',
}

const DailyTask = ({visible, onClose}) => {
  const [chooseVisible, setChooseVisible] = useState(false)
  const [taskStatus, setTaskStatus] = useState({})

  useEffect(() => {
    if (visible) {
      queryTaskStatus()
    }
  }, [visible])

  const queryTaskStatus = async () => {
    try {
      showLoading()
      const res = await dailyTaskStatus()
      const { code, msg, data } = res
      hideLoading()
      if (code !== '000000') throw msg
      setTaskStatus(data)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const onBtn = () => {
    if (taskStatus.status === 2) return
    if (taskStatus.status === 0) {
      window.JsBridge.startUnity3d({
        sceneName: 'shop',
        isLandscape: 1,
      })
      setTimeout(() => {
        onClose()
      }, 3000);
    } else if (taskStatus.status === 1) {
      setChooseVisible(true)
    }
  }

  return <Mask
    visible={visible}
    destroyOnClose
    style={{background: 'rgba(0, 0, 0, .8)', zIndex: 99}}
  >
    <div className={styles.daily_task}>
      <Title title="每日任务"/>
      <CloseBtn onClick={onClose}/>
      <div className={styles.task_item}>
        <div className={styles.item_left}>
          <p>完成元宇宙每日任务</p>
          <span>{taskStatus.status === 0 ? 0: 1}/1</span>
        </div>
        <div className={styles.item_middle}>
          <img src={exp} alt="" />
          <span>+1000</span>
        </div>
        <div 
          className={`${styles.item_right} ${taskStatus.status === 2 ? styles.disabled: ''}`}
          onClick={onBtn}
        >
          {BtnMap[taskStatus.status]}
        </div>
      </div>
      <ChooseLand visible={chooseVisible} onClose={() => setChooseVisible(false)} refreshStatus={queryTaskStatus}/>
    </div>
  </Mask>
}

const ChooseLand = ({visible, onClose, refreshStatus}) => {
  const [currentLand, setCurrentLand] = useState(null)
  const [landList, setLandList] = useState([])

  useEffect(() => {
    if (!visible) {
      setCurrentLand(null)
    } else {
      getSpaceLandList()
    }
  }, [visible])

  const chooseLand = (item) => {
    if (item.nftNo === currentLand?.nftNo) {
      setCurrentLand(null)
    } else {
      setCurrentLand(item)
    }
  }

  const getSpaceLandList = async () => {
    try {
      const res = await spaceLandList()
      if (res.code !== '000000') throw res.msg
      setLandList(res.data)
    } catch (error) {
      Toast.show(error)
    }
  }

  const onConfirm = async () => {
    if (!currentLand) return
    try {
      showLoading()
      const res = await dailyTaskToLand({landId: currentLand?.id})
      hideLoading()
      if (res.code !== '000000') throw res.msg
      onClose()
      refreshStatus()
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  return <Popup
    visible={visible}
    position='left'
    destroyOnClose
    className={styles.popup_mask}
    bodyStyle={{
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      height: '100%',
      background: 'transparent',
      paddingLeft: 0,
    }}
  >
    <div className={styles.choose_land}>
      <Title title="选择土地" className={styles.title}/>
      <div className={styles.close_popup} onClick={onClose}><img src={close} alt="" /></div>
      <p className={styles.choose_tip}>选择土地，把经验放进该土地里</p>
      <div className={styles.land_list}>
        {
          landList.length > 0 ?
          landList.map((item, index) => {
            return <div className={styles.list_item} key={index} onClick={() => chooseLand(item)}>
              <div className={styles.land_img_box}><img className={styles[StyleMap[item.collectionId]] || ''} src={item.nftImage} alt="" /></div>
              <span className={styles.land_no}>#{item.nftNo}</span>
              <span className={styles.land_level}>{item.expLevel}级 {item.exp}/{item.expMax}</span>
              <img className={styles.select_img} src={item.nftNo === currentLand?.nftNo ? xuanzhong: weixuanzhong} alt="" />
              {item.ownFlag === 1 ? <div className={styles.lease_tag}></div>: null}
            </div>
          }): <div className={styles.no_data}>暂无数据</div>
        }
      </div>
      <div className={styles.choose_btn_box} onClick={onConfirm}>
        <span className={`${styles.confirm_btn} ${currentLand ? styles.active: styles.disabled}`}>确认</span>
      </div>
    </div>
  </Popup>
}

export default DailyTask
import React, { useEffect, useState } from "react";
import landC1 from '@/assets/img/landfi/img_land2_c1.png'
import landC2 from '@/assets/img/landfi/img_land2_c2.png'
import landC3 from '@/assets/img/landfi/img_land2_c3.png'
import landC4 from '@/assets/img/landfi/img_land2_c4.png'
import landC5 from '@/assets/img/landfi/img_land2_c5.png'
import landB from '@/assets/img/landfi/img_land2_b.png'
import landA from '@/assets/img/landfi/img_land2_a.png'
import landA2 from '@/assets/img/landfi/a2.png'
import landA4 from '@/assets/img/landfi/a4.png'
import c1_a from '@/assets/img/landfi/c1-a.gif'
import c1_b from '@/assets/img/landfi/c1-b.gif'
import c2_a from '@/assets/img/landfi/c2-a.gif'
import c2_b from '@/assets/img/landfi/c2-b.gif'
import c4_a from '@/assets/img/landfi/c4-a.gif'
import c4_b from '@/assets/img/landfi/c4-b.gif'
import c3_a from '@/assets/img/landfi/c3-a.gif'
import c3_b from '@/assets/img/landfi/c3-b.gif'
import c5_a from '@/assets/img/landfi/c5-a.gif'
import c5_b from '@/assets/img/landfi/c5-b.gif'
import b_a from '@/assets/img/landfi/b-a.gif'
import yincang from '@/assets/img/landfi/yincang.gif'
import shishi from '@/assets/img/landfi/shishi.gif'
import chuanshuo from '@/assets/img/landfi/chuanshuo.gif'
import diancang from '@/assets/img/landfi/diancang.gif'
import worka from '@/assets/img/landfi/a.gif'
import workb from '@/assets/img/landfi/b.gif'
import workc from '@/assets/img/landfi/c.gif'
import works from '@/assets/img/landfi/s.gif'
import replaceLand from '@/assets/img/landfi/exchange-fill.png'

const LandMap = {
  205: { img: landA4, className: 'space-a2', clickBox: 'click-box-a', workApeNum: 5 },
  63: { img: landA2, className: 'space-a2', clickBox: 'click-box-a', workApeNum: 5 },
  48: { img: landA, className: 'space-a', clickBox: 'click-box-a', workApeNum: 5 },
  47: { img: landB, className: 'space-b', clickBox: 'click-box-b', workApeNum: 3 },
  41: { img: landC1, className: 'space-c', clickBox: '', workApeNum: 1 },
  42: { img: landC2, className: 'space-c', clickBox: '', workApeNum: 1 },
  43: { img: landC3, className: 'space-c', clickBox: '', workApeNum: 1 },
  44: { img: landC4, className: 'space-c', clickBox: '', workApeNum: 1 },
  45: { img: landC5, className: 'space-c5', clickBox: 'click-box-c5', workApeNum: 1 },
}

export const MonkeyMap = {
  1: shishi,
  2: chuanshuo,
  3: yincang,
  4: diancang,
  5: workc,
  6: workb,
  7: worka,
  8: works,
}

const LandBox = ({ onClick, selectLand, receiveNum, upgradeData, spaceList, isCanExchangeLand }) => {
  const [gif, setGif] = useState('')
  const [workApeList, setWorkApeList] = useState([])

  useEffect(() => {
    const apeList = Array(LandMap[selectLand?.collectionId]?.workApeNum).fill({}).map((item, index) => {
      return { separateNo: index }
    })
    if (selectLand?.lazyMonkeyLayDOList?.length > 0) {
      selectLand?.lazyMonkeyLayDOList?.forEach((item) => {
        const i = apeList.findIndex((witem) => witem.separateNo === item.separateNo)
        apeList.splice(i, 1, item)
      })
    }
    setWorkApeList(apeList)
  }, [selectLand])

  useEffect(() => {
    const { upgradeType, collectionId } = upgradeData || {}
    let gifImg = ''
    if (upgradeType === 3) {
      gifImg = b_a
    } else if (upgradeType === 1) {
      if (collectionId === 41) {
        gifImg = c1_b
      } else if (collectionId === 42) {
        gifImg = c3_b
      } else if (collectionId === 43) {
        gifImg = c4_b
      } else if (collectionId === 44) {
        gifImg = c5_b
      } else if (collectionId === 45) {
        gifImg = c2_b
      }
    } else if (upgradeType === 2) {
      if (collectionId === 41) {
        gifImg = c1_a
      } else if (collectionId === 42) {
        gifImg = c3_a
      } else if (collectionId === 43) {
        gifImg = c4_a
      } else if (collectionId === 44) {
        gifImg = c5_a
      } else if (collectionId === 45) {
        gifImg = c2_a
      }
    }
    setGif(gifImg)
  }, [upgradeData])

  const placeLand = (selectLand) => {
    const placeList = spaceList.filter((i) => i.nftImage)?.length
    if (selectLand?.nftImage || placeList >= 9) return
    onClick && onClick('place', selectLand)
  }

  const checkLand = (e, selectLand) => {
    console.log('selectLand', selectLand.separateNo)
    e.stopPropagation()
    onClick && onClick('check', selectLand)
  }

  const receiveLand = (e) => {
    e.stopPropagation()
    onClick && onClick('receive')
  }

  return <div className="land-game-landitalics" onClick={() => placeLand(selectLand)}>
    {/* {selectLand?.separateNo === 4 && receiveNum ? <div className="land-game-brand">
      <div className="land-game-brand-click-box" onClick={(e) => receiveLand(e)}></div>
    </div>: null} */}
    {
      upgradeData && selectLand?.separateNo === upgradeData?.separateNo ?
        <div className="land-game-space-gif">
          {gif && <img src={gif} alt="" />}
        </div> :
        selectLand?.nftImage &&
        <div
          className={`land-game-space ${LandMap[selectLand?.collectionId]?.className || ''}`}
          onClick={(e) => { e.stopPropagation() }}
        >
          {isCanExchangeLand ? <img className="exchange-land" src={replaceLand} alt="" /> : null}
          {
            workApeList.map((item, index) => {
              return <div key={index} className={`work-monkey _${index}`}>
                {MonkeyMap[item.monkeyType] ? <img src={MonkeyMap[item.monkeyType]} alt="" /> : null}
              </div>
            })
          }
          <img src={LandMap[selectLand?.collectionId]?.img} alt="" onClick={(e) => { e.stopPropagation() }} />
          <div
            className={`land-game-space-click-box ${LandMap[selectLand?.collectionId]?.clickBox || ''}`}
            onClick={(e) => checkLand(e, selectLand)}
          ></div>
          {selectLand.ownFlag === 1 ? <div className="lease-tag"></div> : null}
        </div>
    }
  </div>
};

export const ReceiveTip = ({ receiveNum, onClick }) => {
  return <div className="land-game-receive-tip" onClick={() => onClick('receive')}>
    <span>购买土地</span>
  </div>
}

export default ({ onClick, landData, upgradeData, isCanExchangeLand }) => {
  const [spaceList, setSpaceList] = useState([])

  useEffect(() => {
    let list = Array(9).fill({}).map((item, index) => {
      return {
        collectionId: '',
        nftNo: '',
        nftImage: '',
        separateNo: index,
      }
    })
    if (landData?.landBlocksDTOList?.length > 0) {
      landData?.landBlocksDTOList?.forEach((item) => {
        const i = list.findIndex((ritem) => ritem.separateNo === item.separateNo)
        list.splice(i, 1, item)
      })
    }
    setSpaceList(list)
  }, [landData])

  return (
    <div className="land-game-landwrapper">
      {spaceList.map((landItem, index) => (
        <LandBox
          key={index}
          onClick={onClick}
          selectLand={landItem}
          upgradeData={upgradeData}
          spaceList={spaceList}
          isCanExchangeLand={isCanExchangeLand}
        ></LandBox>
      ))}
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { SpeedBtn, ScoreXBtn } from "../../components/button";
import { Toast } from "antd-mobile";
import rocket from '@/assets/img/landfi/rocket.png';
import classNames from "classnames";

const LevelInfo = ({onSpeed, expGemInfo}) => {
  const [progressLength, setProgressLength] = useState(0)
  const { expLevel = 0, exp = 0, expMax = 0, gemstonesCount = 0, isUseCard, times } = expGemInfo
  
  useEffect(() => {
    const progress = Number((Number(exp)/Number(expMax)).toFixed(2))*100
    setProgressLength(progress > 100 ? 100: progress)
  }, [expGemInfo])

  return (
    <div className={`land-game-levelinfo`}>
      <div className="land-game-levelbar">
        <div className="land-game-levelbarinner" style={{ width: `${progressLength}%`, transition: '1.5s' }}>
          <div className={classNames("exp_num_box", {exp_num: progressLength < 34 && progressLength > 0})}>
            {exp === expMax ? <span>{exp}</span>: <span>{exp}/{expMax}</span>}
          </div>
          {isUseCard === 1 ? <div className={classNames("land-game-levelbarinner-rocket", {exp_100: progressLength === 100})}>
            {progressLength === 0 ? <div className="exp_0"></div>: <img src={rocket} alt="" />}
            <span>{times}倍加速中～</span>
          </div>: null}
        </div>
        <div className="land-game-levelbg">{Number(expLevel) < 10 ? `0${expLevel}`: expLevel}</div>
      </div>
      <SpeedBtn onClick={onSpeed}></SpeedBtn>
      <ScoreXBtn gemCount={gemstonesCount}></ScoreXBtn>
    </div>
  );
};

export default LevelInfo;

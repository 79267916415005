import React, { useState, useEffect, useRef } from "react";
import LevelInfo from "./components/levelInfo";
import LandUpgradation from "./components/landUpgradation";
import ExperienceLevel from "./components/experienceLevel";
import WorkDispatch from "./components/workDispatch";
import { BaseBtn } from "../components/button";
import classnames from "classnames";
import { Tabs, Toast } from "antd-mobile";
import { CloseBtn } from "../components/button";
import { LandDetailContext } from "./context";
import Order from "./components/order";
import { getLandLevel } from "../utils";
import { useSpeedCard } from '../acceleratorCard/myAcceleratorCard';
import { expGemstores, uploadLandExp } from '@/api/guland';

const EmptyView = ({ children }) => <>{children}</>;

const LandDetail = ({ ctrl, upgradeCallBack, pageInit }) => {
  const [currentTab, setCurrentTab] = useState("0");
  const [info, setInfo] = useState({});
  const [show, setShow] = useState(false);
  const [orderShow, setOrderShow] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [expGemInfo, setExpGemInfo] = useState({})
  const landUpRef = useRef(null);
  const workRef = useRef(null);
  const [onOpen, SpeedCard] = useSpeedCard()

  useEffect(() => {
    ctrl.current = {
      show(info, tabIndex = "0") {
        info.currentLevel = getLandLevel(info.collectionId);
        info.landId = info.nftNo;
        info.setFlag = info.setFlag;
        setInfo(info);
        setCurrentTab(tabIndex);
        setShow(true);
      },
    };
  }, []);

  useEffect(() => {
    show && getExpGemstores()
  }, [show])

  const upLandInfo = (upLandInfo) => {
    info.currentLevel = getLandLevel(upLandInfo.collectionId);
    info.landId = upLandInfo.nftNo;
    info.nftImage = upLandInfo.nftImage
    setInfo(info);
    pageInit();
    landUpRef.current && landUpRef.current.getUpgradeInfo(getLandLevel(upLandInfo.collectionId))
  }

  const getExpGemstores = async () => {
    try {
      const res = await expGemstores({landId: info.id, collectionId: info.collectionId, landNftNo: info.landId})
      const { code, msg, data } = res || {}
      if (code !== '000000') throw msg
      setExpGemInfo(data)
    } catch (error) {
      Toast.show(error)
    }
  }

  if (!show) {
    return null;
  }

  return (
    <LandDetailContext.Provider
      value={{
        landInfo: info,
        setCurrentTab,
        upLandInfo,
        closeLandDetail: () => setShow(false),
        upgradeCallBack: upgradeCallBack,
        refreshUpgradeInfo: landUpRef.current && landUpRef.current.getUpgradeInfo,
        refreshExp: () => getExpGemstores(),
        refreshDailyExp: workRef.current && workRef.current.refreshDailyExp,
        pageInit: pageInit,
        currentTab,
        expGemInfo,
        openOrder(args) {
          setOrderShow(true);
          setOrderInfo(args);
        },
      }}
    >
      <div className="land-detail-page land-detail">
        <div
          className={classnames("land-detail-container", {
            hide: orderShow,
          })}
        >
          <LevelInfo onSpeed={() => onOpen()} expGemInfo={expGemInfo}/>
          <div className="land-detail-view">
            <div className="land-detail-btns">
              {["土地升级", "经验等级", "打工派遣"].map((text, i) => (
                <EmptyView key={i}>
                  <div className="mt-24"></div>
                  <BaseBtn
                    onClick={() => setCurrentTab(i.toString())}
                    active={i == currentTab}
                  >
                    {text}
                  </BaseBtn>
                </EmptyView>
              ))}
            </div>
            <div
              className=""
              style={{
                flex: 1,
                position: "relative",
              }}
            >
              <Tabs activeKey={currentTab} onChange={setCurrentTab}>
                <Tabs.Tab title="土地升级" key="0">
                  <LandUpgradation ref={landUpRef}/>
                </Tabs.Tab>
                <Tabs.Tab title="经验等级" key="1">
                  <ExperienceLevel />
                </Tabs.Tab>
                <Tabs.Tab title="打工派遣" key="2">
                  <WorkDispatch ref={workRef} pageInit={pageInit}/>
                </Tabs.Tab>
              </Tabs>
            </div>
          </div>

          <CloseBtn
            onClick={() => {
              setShow(false);
              setExpGemInfo({})
            }}
          ></CloseBtn>
        </div>

        {orderShow && (
          <Order
            onClose={() => {
              setOrderShow(false);
            }}
            info={orderInfo}
          ></Order>
        )}
        {SpeedCard}
      </div>
    </LandDetailContext.Provider>
  );
};

export default LandDetail;

import mylandbtn from "@/assets/img/landfi/icon_land.png";
import teleportbtn from "@/assets/img/landfi/icon_door.png";
import classnames from "classnames";
import replaceLand from '@/assets/img/landfi/exchange-fill.png';
import landLease from '@/assets/img/landfi/land-lease-btn.png';
import spaceExtendImg from '@/assets/img/landfi/space-extend-btn.png';
import qs from 'query-string';

const Button = ({ img, text, onClick, className, ...props }) => {
  return (
    <div
      className={classnames("land-game-btn", "land-game-floatbtn", className)}
      onClick={onClick}
      {...props}
    >
      <img className="land-game-floatbtn__img" src={img} alt="" />
      <p className="land-game-floatbtn__text">{text}</p>
    </div>
  );
};

export default Button;

export const MyLandBtn = (props) => {
  return (
    <Button
      className="land-game-floatbtn__myland"
      img={mylandbtn}
      text="我的土地"
      {...props}
    ></Button>
  );
};

export const LandLease = (props) => {
  return (
    <Button
      className="land-game-floatbtn__landlease"
      img={landLease}
      text="土地租赁"
      {...props}
    ></Button>
  );
};

export const SpaceExtend = (props) => {
  return (
    <Button
      className="land-game-floatbtn__spaceextend"
      img={spaceExtendImg}
      text="空间扩容"
      {...props}
    ></Button>
  );
};

export const TeleportBtn = (props) => {
  return (
    <Button
      className="land-game-floatbtn__door"
      img={teleportbtn}
      text="传送门"
      {...props}
    ></Button>
  );
};

export const SpeedBtn = ({ onClick, className }) => {
  return (
    <div
      className={classnames("land-game-btn land-game-speedbtn", className)}
      onClick={onClick}
    ></div>
  );
};

export const ScoreBtn = ({ onClick, storeCounts }) => {
  return (
    <div className="land-game-btn land-game-scorebtn" onClick={onClick}>
      <div>{storeCounts || 0}</div>
    </div>
  );
};

export const ScoreXBtn = ({ onClick, gemCount, style = {} }) => {
  return (
    <div className="land-game-btn land-game-scorexbtn" onClick={onClick} style={{...style}}>
      <div>{gemCount || 0}</div>
    </div>
  );
};

export const BaseBtn = ({ classNamme, active, ...props }) => {
  return (
    <div
      className={classnames("land-game-btn land-game-basebtn", {
        active,
      })}
      {...props}
    ></div>
  );
};

export const CloseBtn = (props) => {
  return <div className="land-game-btn land-game-closebtn" {...props}></div>;
};

export const BackBtn = (props) => {
  return <div className="land-game-btn land-game-backbtn" {...props}></div>;
};

export const GreenBtn = ({
  classNamme,
  active,
  large,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={classnames("land-game-btn land-game-greenbtn", {
        "land-game-greenbtn__landup": large,
        "land-game-greenbtn__disabled": disabled,
        "land-game-greenbtn__large-disabled": (disabled && large),
        active,
      })}
      {...props}
    ></div>
  );
};

export const OrangeBtn = ({
  classNamme,
  active,
  large,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={classnames("land-game-btn land-game-orangebtn", {
        "land-game-orangebtn__disabled": disabled,
      })}
      {...props}
    ></div>
  );
};

export const HomeBackBtn = (props) => {
  return <div 
    className="land-game-btn land-game-homeBackbtn" 
    {...props} 
    ></div>;
}

export const SpaceNum = ({spaceNum}) => {
  return <div className="land-game-spaceNum">{spaceNum || ''}</div>
}

export const SpaceId = ({spaceId, isCustom, spaceNiceNo}) => {
  if (spaceNiceNo) {
    return <div className={`land-game-spaceBeautifulId ${isCustom ? 'spaceBeautifulId-1' : 'spaceBeautifulId-2'}`}>{spaceNiceNo || ''}</div>
  } else {
    return <div className="land-game-spaceId">空间ID: {spaceId || ''}</div>
  }
}

export const TaskBtn = (props) => {
  return <div className="land-game-btn land-game-taskBtn" {...props}></div>;
};

export const ExchangeLandBtn = ({isCanExchangeLand, ...props}) => {
  return <div className="land-game-btn land-game-exchangeLandBtn" {...props}>
    <img src={replaceLand} alt="" />
    <span>{isCanExchangeLand ? '取消更换': '更换土地'}</span>
  </div>;
};
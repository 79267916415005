import React, { useContext, useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { LandMap } from "../../components/land";
import classNames from "classnames";
import { GreenBtn } from "../../components/button";
import { LandDetailContext } from "../context";
import { hideLoading, showLoading } from "@/components/loading";
import { landUpgradeInfo, getHoleStatus } from "@/api/guland";
import { Toast } from "antd-mobile";

const LandItem = ({
  level = "",
  img,
  currentLevel,
  active,
  onClick,
}) => {
  const isCurrent = level === currentLevel;
  const canUp = currentLevel > level;

  return (
    <div onClick={onClick} className={classNames("land-up-tab", { active })}>
      <div>
        {React.cloneElement(LandMap[level], {
          disabled: !canUp || isCurrent,
          img,
        })}
        <span className="land-up-tab__leveltext">{level.toUpperCase()}级</span>
      </div>

      {(canUp || isCurrent) && (
        <p
          className={classNames("land-up-tab__bardge", {
            current: isCurrent,
            up: canUp,
          })}
        >
          {isCurrent ? "当前等级" : "可升级"}
        </p>
      )}
    </div>
  );
};

const landLevelMap = {
  a: 2,
  b: 1,
  c: 0,
};

const deLandLevelMap = ["c", "b", "a"];

// 获取土地升级信息 Pro
export const getLandUpgradeInfo = async (currentLevel) => {
  const res = await landUpgradeInfo();
  if (res.code !== "000000") throw res.msg;

  const curLevel = landLevelMap[currentLevel];

  const { A_LEVEL, B_LEVEL, C_LEVEL, C_A, C_B, B_A } = res.data;
  const list = [C_LEVEL, B_LEVEL, A_LEVEL];

  return list.map((info, i) => {
    if (curLevel >= i) {
      return info;
    }
    return {
      ...info,
      collection: info.collection - list[curLevel].collection,
      precious: info.precious - list[curLevel].precious,
      canUp: true,
      price: curLevel === 1 ? B_A : i === 1 ? C_B : C_A,
    };
  });
};

const LandUpgradation = forwardRef((props, ref) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [upgradeInfo, setUpgradeInfo] = useState([]);
  const [collectionStatus, setCollectionStatus] = useState('');

  const { openOrder, landInfo } = useContext(LandDetailContext);
  const { currentLevel, landId } = landInfo;

  useImperativeHandle(ref, () => {
    return {
      getUpgradeInfo: getUpgradeInfo,
    }
  })

  useEffect(() => {
    getUpgradeInfo(currentLevel);
    queryHoleStatus()
  }, [landInfo]);

  const onUpLevel = (i) => {
    setCurrentTab(i);
  };

  const targetLevel = deLandLevelMap[currentTab];

  const canUp = currentLevel > deLandLevelMap[currentTab];

  const getUpgradeInfo = async (currentLevel) => {
    try {
      showLoading();
      const res = await getLandUpgradeInfo(currentLevel);
      setUpgradeInfo(res);
      setCurrentTab(landLevelMap[currentLevel])
      hideLoading();
    } catch (error) {
      hideLoading();
    }
  };

  const queryHoleStatus = async () => {
    try {
      const res = await getHoleStatus(landInfo.nftNo);
      if (res.code !== "000000") throw res.msg;
      setCollectionStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const showInfo = upgradeInfo[currentTab];

  return (
    <div className="land-detail-landupbox">
      <div className={"land-detail-landup land-game-shadow"}>
        <LandItem
          level="c"
          currentLevel={currentLevel}
          active={currentTab === 0}
          onClick={() => onUpLevel(0)}
          img={ currentLevel==='c'? landInfo.nftImage : undefined }
        ></LandItem>

        <LandItem
          level="b"
          currentLevel={currentLevel}
          active={currentTab === 1}
          onClick={() => onUpLevel(1)}
          img={ currentLevel==='b'? landInfo.nftImage : undefined }
        ></LandItem>

        <LandItem
          level="a"
          currentLevel={currentLevel}
          active={currentTab === 2}
          onClick={() => onUpLevel(2)}
          img={ currentLevel==='a'? landInfo.nftImage : undefined }
        ></LandItem>
      </div>
      <div></div>
      <div className="land-up-middle">
        <div className="land-up-no">土地编号：#{landId}</div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {currentTab === 0 && React.cloneElement(LandMap["c"], {
            img: currentLevel === "c" ? ["c", "b", "a"][currentTab] === 'c' ? landInfo.nftImage : undefined: undefined,
            workStyle: false,
            scale: 0.8
          })}
          {currentTab === 1 && React.cloneElement(LandMap["b"], {
            img: currentLevel === "b" ? ["c", "b", "a"][currentTab] === 'b' ? landInfo.nftImage : undefined: undefined,
            workStyle: false,
            scale: 0.8
          })}
          {currentTab === 2 && React.cloneElement(LandMap["a"], {
            img: currentLevel === "a" ? ["c", "b", "a"][currentTab] === 'a' ? landInfo.nftImage : undefined: undefined,
            workStyle: false,
            scale: 0.8
          })}
        </div>
      </div>
      <div className="land-game-divide"></div>
      <div className="land-up-right">
        <div>
          <div>
            <p>
              {canUp ? "升到" : ""} {targetLevel.toUpperCase()} 级
            </p>
            <div className="land-up-right__info">
              <div>
                <div></div>
                <p>宝石</p>
                <div className="land-flex-vmiddle">
                  <div
                    className={classNames("land-up-icon", {
                      "land-game-none": !canUp,
                    })}
                  ></div>
                  <span>{showInfo?.precious}</span>
                </div>
              </div>
              <div>
                <div></div>
                <p>藏品</p>
                <div className="land-flex-vmiddle">
                  <div
                    className={classNames("land-up-icon", {
                      "land-game-none": !canUp,
                    })}
                  ></div>
                  <span>{showInfo?.collection}</span>
                </div>
              </div>
            </div>
          </div>

          {canUp && (
            <div 
              onClick={() => {
                if (collectionStatus === 1) {
                  return
                }
                Toast.show('该土地是租来的，无法升级')
              }}
            >
              <GreenBtn
                large
                disabled={collectionStatus !== 1}
                onClick={() =>
                  openOrder({
                    level: deLandLevelMap[currentTab],
                    currentLevel,
                    price: showInfo?.price,
                  })
                }
              >
                <span style={{fontFamily: 'PingFang SC'}}>¥</span>
                {showInfo?.price}升级土地
              </GreenBtn>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default LandUpgradation;

import React, { useState, useRef, useEffect, useContext } from 'react'
import styles from './selectApe.module.scss'
import { Popup, Tabs, Swiper, Toast } from 'antd-mobile'
import { Title } from '../../components/common'
import close from '@/assets/img/landfi/icon_del.png'
import tabActive from '@/assets/img/landfi/tab-active.png'
import ListView from "@/components/AntListView"
import xuanzhong from '@/assets/img/landfi/xuanzhong.png'
import { dispatchWorkApe, myApeCollections } from '@/api/guland'
import { hideLoading, showLoading } from "@/components/loading"
import { LandDetailContext } from "../context"

const SelectApe = ({visible, onClose, expData, selectCB, separateNo}) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [isChoice, setIsChoice] = useState(false)
  const [selectApeInfo, setSelectApeInfo] = useState(null)
  const swiperRef = useRef(null)

  const { landInfo } = useContext(LandDetailContext)

  useEffect(() => {
    if (!visible) {
      setCurrentTab(0)
      setIsChoice(false)
    }
  }, [visible])

  const onTabClick = (i) => {
    setCurrentTab(i)
    swiperRef.current.swipeTo(i)
  }

  const onSelect = async (item) => {
    if (item.isLay === 1) return
    if (item.nftNo === selectApeInfo?.nftNo) {
      setIsChoice(false)
      setSelectApeInfo(null)
    } else {
      setSelectApeInfo(item)
      setIsChoice(true)
    }
    try {
      showLoading()
      const res = await dispatchWorkApe({
        landId: landInfo.id,
        monkeyType: item.type,
        collectionId: item.collectionId || '',
        nftNo: item.nftNo || '',
        separateNo,
      })
      hideLoading()
      const { code, msg } = res
      if (code !== '000000') throw msg
      onClose()
      selectCB && selectCB()
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  } 

  return <>
    <Popup
      visible={visible}
      destroyOnClose
      position='left'
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        height: '100%',
        background: 'transparent',
        paddingLeft: 0
      }}
    >
      <div className={styles.select_ape}>
        <Title title="选择无聊猿" className={styles.title}/>
        <div className={styles.close_popup} onClick={onClose}><img src={close} alt="" /></div>
        <Tabs className={styles.tabs} activeKey={currentTab} onChange={(key) => onTabClick(Number(key))}>
          {expData.length > 0 && expData.map((item, index) => <Tabs.Tab 
            key={index} 
            title={<div className={`${styles.tab_title} ${currentTab === index ? styles.tab_select: ''}`}>
              <div className={styles.tab_text}>{item.monkeyTypeName}</div>
              <div className={styles.tab_active}>
                {currentTab === index ? <img src={tabActive} alt="" />: null}
                <span></span>
              </div>
            </div>}
          ></Tabs.Tab>)}
        </Tabs>
        {expData.length > 0 && <Swiper
          direction='horizontal'
          indicator={() => null}
          ref={swiperRef}
          allowTouchMove={false}
          defaultIndex={currentTab}
        >
          {
            expData.map((item, index) => {
              return <Swiper.Item key={index}>
                <ApeList 
                  focusStatus={currentTab === index} 
                  type={expData[currentTab]?.monkeyType} 
                  isChoice={isChoice} 
                  onSelect={onSelect}
                  selectApeInfo={selectApeInfo}
                />
              </Swiper.Item>
            })
          }
        </Swiper>}
      </div>
    </Popup>
  </>
}

const ApeList = ({focusStatus, type, isChoice, onSelect, selectApeInfo}) => {
  const [initStatus, setInitStatus] = useState(false)
  const [apeData, setApeData] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const currentPage = useRef(1);
  const pageSize = useRef(20);

  useEffect(() => {
    if (!initStatus && focusStatus) {
      setInitStatus(true);
      getApeList();
      return;
    }
  }, [initStatus, focusStatus])

  const getApeList = async (isRefresh=true) => {
    try {
      if(isRefresh) {
        currentPage.current = 1;
      } else {
        currentPage.current = currentPage.current + 1;
      }
      const params = {
        pageNum: currentPage.current,
        pageSize: pageSize.current,
        monkeyType: type,
      }
      console.log(params)
      const res = await myApeCollections(params)
      if (res.code !== '000000') throw res.msg
      if(currentPage.current === 1) {
        setApeData(res?.data?.userCollectionRespList || [])
      } else {
        setApeData([...apeData, ...res?.data?.userCollectionRespList]);
      }
      isRefresh && setIsLoad(true);
      setHasMore(res?.data?.userCollectionRespList?.length >= pageSize.current)
    } catch (error) {
      console.log(error)
      Toast.show(error)
    }
  }

  const listItem = (item) => {
    return <div 
      className={styles.list_item}
      onClick={() => onSelect(item)}
    >
      <div className={styles.list_item_inner}>
        <div 
          className={`${styles.land_img} ${((isChoice && selectApeInfo?.nftNo === item.nftNo) || item.isLay === 1) ? styles.active: ''}`}
        >
          <img src={item.nftImage} alt=""/>
        </div>
        <p>#{item.nftNo}</p>
        {((isChoice && selectApeInfo?.nftNo === item.nftNo) || item.isLay === 1) ? <img className={styles.select_img} src={xuanzhong} alt="" />: null}
        {item.ownFlag === 1 ? <div className={styles.lease_tag}></div>: null}
      </div>
    </div>
  }
  return <div className={styles.ape_list}>
    {
      apeData?.length > 0 ? <ListView
        dataSource={apeData}
        hasMore={hasMore}
        style={{paddingTop: 5}}
        renderItem={listItem}
        onEndReached={async () => {
          await getApeList(false);
        }}
        isLoad={isLoad}
      />: !isLoad ? null: <div className={styles.no_data}>暂无数据</div>
    }
  </div>
}

export default SelectApe
import React, { useContext, useEffect, useRef, useState } from "react";
import { LandC, LandA, LandB } from "../../components/land";
import { OrangeBtn, GreenBtn } from "../../components/button";
import classNames from "classnames";
import { expLevelInfo, receiveLevelPrize } from "@/api/guland";
import { hideLoading, showLoading } from "@/components/loading";
import { LandDetailContext } from "../context";
import { getLandIndex } from "../../utils";
import { Mask, Toast } from 'antd-mobile';

const LevelMap = {
  a: 1,
  b: 2,
  c: 3
}

const ExpLandBox = ({
  sticky = false,
  level = 0,
  shadow = false,
  activeStatus = 0,
}) => {
  const { landInfo } = useContext(LandDetailContext);

  return (
    <div
      className={classNames("land-game-exp__box", {
        sticky,
        "land-game-shadow": shadow,
      })}
    >
      <div> {level > 0 ? `${level} 级` : ""}</div>
      <div>
        <div
          className={`land-game-box__${
            activeStatus === 0 ? "active" : "default"
          }`}
        >
          <LandC scale={0.6} img={landInfo.currentLevel === 'c' ? landInfo.nftImage: ''}></LandC>
          <span>C级土地</span>
        </div>
        <div
          className={`land-game-box__${
            activeStatus === 1 ? "active" : "default"
          }`}
        >
          <LandB scale={0.6} img={landInfo.currentLevel === 'b' ? landInfo.nftImage: ''}></LandB>
          <span>B级土地</span>
        </div>
        <div
          className={`land-game-box__${
            activeStatus === 2 ? "active" : "default"
          }`}
        >
          <LandA scale={0.6} img={landInfo.currentLevel === 'a' ? landInfo.nftImage: ''}></LandA>
          <span>A级土地</span>
        </div>
      </div>
    </div>
  );
};

const ExpBoxItem = ({prizeInfo, onReceive}) => {
  const { prize, prizeImage, prizeNum, isLock = 1, isReceived = 0 } = prizeInfo || {}

  /* 根据类型处理，默认都是200钻石 */
  if (prize === 1) {
    return null;
  }

  return (
    <div
      className={classNames("land-game-exp__box-el", {
        "land-game-exp__box-el__canget": isLock === 0 && isReceived === 0,
      })}
      onClick={() => {
        if (isLock === 1 || isReceived === 1) return
        onReceive(prizeInfo)
      }}
    >
      {isLock === 1 ? <div className="land-game-exp__box-el__lock"></div>: 
        isReceived === 0 ? <div className="land-game-exp__box-el__isReceived"></div>: 
      <div className="land-game-exp__box-el__received"></div>}
      {prize === 2 && (
        <div
          className={classNames("land-game-exp__box-el__score", {
            __lock: isLock === 1,
          })}
        >
          <img src={prizeImage} alt="" />
          <span className={classNames({__can__receive: isLock === 0 && isReceived === 0})}>{prizeNum}</span>
        </div>
      )}
      {prize === 3 && (
        <div
          className={classNames("land-game-exp__box-el__star", {__lock: isLock === 1})}
        >
          {prizeImage && <img src={prizeImage} alt="" />}
          {prizeNum > 1 ? <span className={classNames({__can__receive: isLock === 0 && isReceived === 0})}>{prizeNum}</span>: null}
        </div>
      )}
    </div>
  );
};

const ExpBox = ({
  sticky = false,
  level = 0,
  shadow = false,
  info,
  onReceive,
}) => {

  return (
    <>
      <div
        className={classNames("land-game-exp__box", {
          sticky,
          "land-game-shadow": shadow,
        })}
      >
        <div> {level > 0 ? `${level} 级` : ""}</div>

        <div>
          {info.map((x, i) => {
            return (
              <div key={i}>
                {!!x && (
                  <ExpBoxItem prizeInfo={x} onReceive={onReceive}></ExpBoxItem>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const ExperienceLevel = () => {
  const [list, setList] = useState([[], [], []]);
  const [levelInfo, setLevelInfo] = useState({})
  const [isCanReceiveAll, setIsCanReceiveAll] = useState(false)
  const [visible, setVisible] = useState(false)
  const [receiveList, setReceiveList] = useState([])
  const [isCenter, setIsCenter] = useState(false)

  const { landInfo, setCurrentTab, refreshExp, pageInit } = useContext(LandDetailContext);

  const activeStatus = getLandIndex(landInfo.currentLevel);

  useEffect(() => {
    getExpLevelInfo();
  }, []);

  const getExpLevelInfo = async () => {
    try {
      showLoading();
      const res = await expLevelInfo({landId: landInfo.id, collectionId: landInfo.collectionId, nftNo: landInfo.landId});
      if (res.code !== '000000') throw res.msg
      const { 1: a, 2: b, 3: c } = res?.data?.landExpRewardRespMap || {};
      const canReceiveAll = res?.data?.landExpRewardRespMap?.[LevelMap[landInfo.currentLevel]]?.some(({isLock, isReceived}) => {
        return (isLock === 0 && isReceived === 0)
      })
      setIsCanReceiveAll(canReceiveAll)
      setList([c || [], b || [], a || []]);
      setLevelInfo(res?.data)
      hideLoading();
    } catch (error) {
      console.log(error)
      hideLoading();
      Toast.show(error)
    }
  };

  const receivePrize = async (expLevel) => {
    try {
      showLoading()
      const res = await receiveLevelPrize({nftNo: landInfo.landId, landId: landInfo.id, expLevelList: expLevel ? [expLevel]: null})
      hideLoading()
      const { code, msg, data } = res
      if (code !== '000000') throw msg
      setReceiveList(data || [])
      setVisible(true)
    } catch (error) {
      hideLoading()
      Toast.show(error)
    }
  }

  const receiveAll = async () => {
    if (!isCanReceiveAll) return
    receivePrize('')
  }

  const receiveSingle = async (prizeInfo) => {
    receivePrize(prizeInfo.expLevel)
  }

  const len = Math.max(list[0]?.length, list[1]?.length, list[2]?.length) || 0;

  return (
    <div className="land-game-exp">
      <div className="land-game-exp__scrollbox">
        <div className="land-game-exp__scrollbox-bg">
          <p></p>
          <div>
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <p
                  className={classNames({
                    __active: activeStatus === i,
                  })}
                  key={i}
                ></p>
              ))}
          </div>
        </div>
        <div className="land-game-exp__scroll">
          <div className="land-game-expscroll-box">
            <ExpLandBox sticky shadow activeStatus={activeStatus}></ExpLandBox>
            {len > 0 ? Array(len)
              .fill(0)
              .map((x, i) => (
                <ExpBox
                  level={i + 1}
                  activeStatus={activeStatus}
                  key={i}
                  info={[list[0]?.[i], list[1]?.[i], list[2]?.[i]]}
                  onReceive={receiveSingle}
                ></ExpBox>
              )): null}
          </div>
        </div>
      </div>

      <div className="land-game-exp__bottom">
        <span>
          本期剩余时间：{levelInfo?.remainTimeStr}
        </span>
        <div>
          <OrangeBtn
            onClick={() => {
              setCurrentTab("0");
            }}
          >
            升级土地
          </OrangeBtn>
          <div className="land-width-52"></div>
          <GreenBtn disabled={!isCanReceiveAll} onClick={() => receiveAll()}>一键领取</GreenBtn>
        </div>
      </div>
      <Mask
        visible={visible}
        afterClose={() => {
          getExpLevelInfo()
          refreshExp()
          pageInit(undefined, false)
        }}
        afterShow={() => {
          const wList = document.getElementsByClassName("receive-prize-list")[0]?.offsetWidth
          const wItem = Array.from(document.getElementsByClassName("prize-list-item") || [])?.reduce((pre, cur) => {
            return pre + (cur.offsetWidth + parseInt(window.getComputedStyle(cur)['margin-right']))
          }, 0)
          const center = Number(wItem) < Number(wList)
          setIsCenter(center)
        }}
        style={{
          width: '100vw',
          height: '100vh',
          background: 'linear-gradient(180deg, #000000 0%, rgba(62, 62, 62, 0.9) 114%)',
        }}
      >
        <div className="receive-mask-content">
          <div className="receive-mask-bg">
            <div className={`receive-prize-list ${isCenter ? '': 'item-center'}`}>
              {
                receiveList.length > 0 && receiveList.map((item, index) => {
                  return <div className="prize-list-item" key={index}>
                    <div className="prize-box">
                      {item.prize === 2 && <div className="gemstone-box">
                        <img src={item.prizeImage} alt="" />
                        <div>{item.prizeNum}</div>
                      </div>}
                      {item.prize === 3 && <div className="ape-box">
                        <img src={item.prizeImage} alt="" />
                        {item.prizeNum > 1 ? <div>{item.prizeNum}</div>: null}
                      </div>}
                    </div>
                    <p>{item.prizeName}</p>
                  </div>
                })
              }
            </div>
          </div>
          <div className="receive-mask-close" onClick={() => setVisible(false)}>点击空白区域继续</div>
        </div>
      </Mask>
    </div>
  );
};

export default ExperienceLevel;
